import * as React from "react";
import Layout from "../../layout";
import Why from "../../components/HomePage/WhoAreWe/WhoAreWe";
import How from "../../components/HomePage/HowItWorks/HowItWorks";

const WhyCorsnetPage = () => {
  return (
    <Layout
      title={"Why Corsnet"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in Sri Lanka!"
      }
    >
      <Why />
      <How />
    </Layout>
  );
};

export default WhyCorsnetPage;
