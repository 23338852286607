import React from "react";
import Reliable from "../../../images/SandboxIcons/reliable.svg";
import Accuracy from "../../../images/SandboxIcons/accuracy.svg";
import Smarter from "../../../images/SandboxIcons/smarter.svg";
import Simplified from "../../../images/SandboxIcons/simplified.svg";
import WhyCorsnetImage from "../../../images/why-corsnet.jpeg";
import { Link } from "gatsby";

export default () => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-10 py-md-12">
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div className="col-md-8 col-lg-6 col-xl-5 order-lg-2 position-relative text-center">
            <figure className="rounded">
              <img src={WhyCorsnetImage} alt="" />
            </figure>
            <Link
              to="/about-us/"
              className="btn btn-primary mt-8 rounded-pill mx-auto"
            >
              Read more
            </Link>
          </div>
          <div className="col-lg-6">
            <h2 className="display-4 mb-3">Why CORSnet?</h2>
            <p className="mb-6">
            CORSnet is the largest  Continuous Observation Reference Station (CORS) Network implemented in Sri Lanka for the first time for Real-Time Correction (RTK) broadcasting services. Users of CORSnet can expect centimetre level  RTK accuracies and raw “Rinex” data for post processing.
            </p>
            <div className="row gx-xl-10 gy-6">
              <div className="col-md-12">
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src={Reliable}
                      className="icon-svg icon-svg-sm text-primary me-4"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="mb-1">
                      The largest and most reliable service in the country{" "}
                    </h4>
                    <p className="mb-0">
                      CORSnet has more than 15 reference stations covering most
                      parts of Sri Lanka to provide you with a reliable RTK
                      Correction service for your positioning requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src={Accuracy}
                      className="icon-svg icon-svg-sm me-4"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="mb-1">Centimeter level accuracy</h4>
                    <p className="mb-0">
                      Highly reliable and accurate positioning accuracy up to
                      centimeter level.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src={Smarter}
                      className="icon-svg icon-svg-sm me-4"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="mb-1">Work smarter- Make life easier</h4>
                    <p className="mb-0">
                      Fast and efficient surveying solutions with a simple ‘Plug
                      & Play’ connectivity.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src={Simplified}
                      className="icon-svg icon-svg-sm me-4"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="mb-1">Simplified Usage</h4>
                    <p className="mb-0">
                      Users willing to engage CORS subscriptions in GNSS
                      applications could login into the network with the GNSS
                      receivers using pre-purchased CORSnet login credentials
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
