import React from "react";
import HowItWorks from "../../../images/how-it-works.jpeg";

export default () => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-10 py-md-12">
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div className="col-md-8 col-lg-6 col-xl-5 position-relative">
            <figure className="rounded">
              <img src={HowItWorks} alt="" />
            </figure>
          </div>
          <div className="col-lg-6">
            <h2 className="display-4 mb-3">How It Works</h2>
            <p className="mb-6">
              A CORS Network is a virtual RTK correction broadcasting network
              comprises of several permanent ground located high accuracy GNSS
              receivers. These networks are commonly called NRS or VRS networks.
              Availability of these types of network eliminates the necessity of
              having ground marked reference points for control surveying.
              <br />
              <br />
              Location data observed by several high accuracy GNSS receivers,
              commonly known as CORS are being processed at a central server.
              Processed data will be broadcasted to RTK Receivers via GPRS
              allowing users to obtain high accuracy positioning coordinates.
              <br />
              <br />
              Today, CORS Networks are operating in many countries over the
              world, such as the US, Canada, UK, Ireland, Germany, Spain, Hong
              Kong and parts of Australia, just to name a few. CORS Networks can
              vary in size, from small local networks consisting of one or only
              a few reference stations, to dozens of reference stations covering
              large areas.
              <br />
              <br />
              Users who want to engage in RTK, GIS, and Post Processing
              surveying have simply login to the network with their GNSS
              receivers using pre purchased login credentials. Once logged in,
              differential corrections will be received via GPRS to the users’
              device making the working mode into Fixed or RTD.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
